<template>
  <div class="footer">
    <div
      class="row list-row col-f col-lgpc-8 col-mdpc-10 col-lgmobile-displaynone-important col-mdmobile-displaynone-important col-smobile-displaynone-important"
    >
      <div class="col-lgpc-4 col-mdpc-4 col-lgmobile-4">
        <ul>
          <li>
            <a href="https://docs.leafcad.com/">{{ $t('footer_component.documentations') }}</a>
          </li>
          <li>
            <a href="https://leafcad.com/support">{{ $t('footer_component.support') }}</a>
          </li>
        </ul>
      </div>
      <div class="col-lgpc-4 col-mdpc-4 col-lgmobile-4 v-step-language-picker">
        <LanguageSelector></LanguageSelector>
      </div>
      <div class="col-lgpc-4 col-mdpc-4 col-lgmobile-4">
        <ul>
          <li>
            <a href="https://leafcad.com/terms#termsofservice">{{ $t('footer_component.terms-of-service') }}</a>
          </li>
          <li>
            <a href="https://leafcad.com/terms#privacypolicy">{{ $t('footer_component.privacy-policy') }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row social-row col-lgmobile-displaynone col-mdmobile-displaynone col-smobile-displaynone">
      <div class="col-pc-12">
        <ul class="col-pc-8 col-f">
          <li>
            <a href="//fb.me/leafcad" target="_blank">
              <i class="fab fa-facebook-f"></i>
            </a>
          </li>
          <li>
            <a href="//twitter.com/leafcad" target="_blank">
              <i class="fab fa-twitter"></i>
            </a>
          </li>
          <li>
            <a href="mailto:contact@leafcad.com">
              <i class="fas fa-envelope"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row copyrights-row col-lgmobile-displaynone col-mdmobile-displaynone col-smobile-displaynone">
      <div class="col-pc-12">
        <div class="_copyrights">
          &#169;{{ new Date().getFullYear() }} <a href="//leafcad.com">LeafCAD</a> &#38;
          <a href="//www.squaretymedia.com">Squarety Média</a>
          &#124; {{ $t('footer_component.all-rights-reserved') }}
        </div>
      </div>
    </div>
    <div
      class="mobile-row mobile-language-row col-pc-displaynone col-lgmobile-12 col-mdmobile-12 col-smobile-12 v-step-language-picker"
    >
      <LanguageSelector></LanguageSelector>
    </div>
    <div class="mobile-row mobile-links-row col-pc-displaynone col-lgmobile-12 col-mdmobile-12 col-smobile-12">
      <ul class="col-lgmobile-10 uk-margin-auto">
        <li>
          <a href="https://docs.leafcad.com/">{{ $t('footer_component.documentations') }}</a>
        </li>
        <li>
          <a href="https://leafcad.com/support">{{ $t('footer_component.support') }}</a>
        </li>
        <li>
          <a href="https://leafcad.com/terms#termsofservice">{{ $t('footer_component.terms-of-service') }}</a>
        </li>
        <li>
          <a href="https://leafcad.com/terms#privacypolicy">{{ $t('footer_component.privacy-policy') }}</a>
        </li>
      </ul>
    </div>
    <div class="mobile-row mobile-social-row col-pc-displaynone col-lgmobile-12 col-mdmobile-12 col-smobile-12">
      <div class="col-mobile-12">
        <ul class="col-mobile-8 col-f">
          <li>
            <a href="//fb.me/leafcad" target="_blank">
              <i class="fab fa-facebook-f"></i>
            </a>
          </li>
          <li>
            <a href="//twitter.com/leafcad" target="_blank">
              <i class="fab fa-twitter"></i>
            </a>
          </li>
          <li>
            <a href="mailto:contact@leafcad.com">
              <i class="fas fa-envelope"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="mobile-row mobile-copyrights-row col-pc-displaynone col-lgmobile-12 col-mdmobile-12 col-smobile-12">
      <div class="col-mobile-10 uk-text-center uk-margin-auto">
        <div class="_copyrights">
          &#169;{{ new Date().getFullYear() }} <a href="//leafcad.com">LeafCAD</a> &#38;
          <a href="//www.squaretymedia.com">Squarety Média</a>
          &#124; {{ $t('footer_component.all-rights-reserved') }}
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/components/footer.less"></style>

<script>
import LanguageSelector from '@/components/LanguageSelector.vue'

export default {
  name: 'Footer',
  components: {
    LanguageSelector
  }
}
</script>
